<template>
  <v-card class="elevation-0" style="background: transparent">
    <v-toolbar dense dark :tile="tile" :color="getColor">
      <template v-if="voltar">
        <v-btn icon tile @click="backHistory">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-divider class="mr-2 mr-md-4" vertical></v-divider>
        <v-spacer></v-spacer>
      </template>
      <v-toolbar-title
        :class="$vuetify.breakpoint.mdAndDown ? 'body-2 text-wrap' : ''"
        class="text-uppercase"
      >
        <v-icon style="margin-bottom: 2px;" class="mr-2">
          {{ icon }}
        </v-icon>
        {{ title }}
      </v-toolbar-title>
      <template v-if="add">
        <v-spacer></v-spacer>
        <slot name="add"></slot>
      </template>
    </v-toolbar>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PageToolbar",
  props: {
    icon: String,
    title: String,
    color: String,
    voltar: Boolean,
    tile: Boolean,
    add: Boolean,
    irParaUrl: String,
  },
  computed: {
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    getColor() {
      if (this.color) {
        return this.color;
      } else {
        return "toolbar";
      }
    },
    getIrPara() {
      return this.irParaUrl;
    },
  },
  methods: {
    backHistory() {
      // console.log(this.$route);
      if (this.$route.meta.voltar) {
        this.$router.push(this.$route.meta.voltar);
      } else {
        this.$router.go(-1);
      }
    },
    irParaClick() {
      this.$router.push({ name: this.getIrPara });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-wrap {
  max-width: 200px !important;
  display: inline-block !important;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
}
</style>
